import React, { Component } from 'react';

class Error extends Component {

  constructor(props) {
    console.log(props)
    super(props);
  }

  render(){
    const {location} = this.props;
    let path = location.pathname;

    return (
      <div  className="error-container">
      <div id="message">
      <h2>404</h2>
      <h3>Page Not Found</h3>
      <p>The specified URL <code>{path}</code> was not found on this website. Please check the URL for mistakes and try again.</p>
    </div>
    </div>
    );
  }

}

export default Error;