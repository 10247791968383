export const menu_data = {
  default: {
    "path":"/overview",
    "title":"Overview", 
    "iframe_src": "https://data.apps.fao.org/?clean&https://data.apps.fao.org/catalog/terriajs/config/7db765f4-9d30-4e81-86af-9a0b563a72bf.json"
  },
  region_gap_assessment: {
    "path":"/regional_assessment",
    "title":"Regional assessment",
    "iframe_src": "https://tableau.apps.fao.org/views/Regional_assessment2/Introduction?:embed=yes"
  },
  /* country_gap_assessment: {
    "path":"/country_gap_assessment",
    "title":"Country assessment",
    "iframe_src": "https://tableau.apps.fao.org/views/GapAssessment_Country_EC/GapAssessment_Country?:embed=yes"
  }, */ 
  resources: {
    "path":"/resources",
    "title":"Resources", 
    /* "iframe_src": "https://tableau.apps.fao.org/views/Resources_NDC/NDC_Resources?:embed=yes" */
    "iframe_src": "https://tableau.apps.fao.org/views/Resources_ETF/Dashboard1?:embed=yes"
  },
};
